export const LS_FIRM = 'ifrurn.pro_firm';
export const LS_LANG = 'ifrurn.pro_lang';
export const LS_CLIENT_TOKEN = 'ifrurn.pro_client_token';
export const LS_USER_TOKEN = 'ifrurn.pro_user_token';
export const clearAllStorage = () => {
    let copyLS = localStorage;
    for (var attr in copyLS) {
        if (attr !== "lang" && attr !== "imgApiUrl" && attr !== "setItem" && attr !== "getItem" && attr !== "removeItem" && attr !== "clear" && attr !== "key") {
            window.localStorage.removeItem(`${attr}`);
        }
    }
};
