import axios from "axios";
import {LS_CLIENT_TOKEN, LS_LANG, LS_USER_TOKEN} from "../consts/localStorageNaming";
import {DEFAULT_LANG} from "../languages/initLanguage";
import cookie from "js-cookie";
import i18n from "i18next";
import {authThunkLogoutSystem} from "../redux/auth/auth-thunk";
import {findErrorResponse, rejectTransform} from "./utils";

export let instances = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "X-Api-Key": 123456789,
        'User-Token': localStorage.getItem(LS_USER_TOKEN) || '',
        'Client-Token': localStorage.getItem(LS_CLIENT_TOKEN) || '',
        'Language': localStorage.getItem(LS_LANG) || cookie.get('i18next') || i18n.language || DEFAULT_LANG,
        // 'Language': cookie.get('i18next') || i18n.language || DEFAULT_LANG,

    }
});
instances.interceptors.request.use(
    async (config) => {
        config.headers['Language'] = localStorage.getItem(LS_LANG) || cookie.get('i18next') || i18n.language || DEFAULT_LANG;
        return config;
    },
    error => {
        Promise.reject(
            error.response.data,
        )
    }
)


// Response interceptor for API calls
instances.interceptors.response.use((response) => {
    return response
}, async function (err) {
    // сохраняем данные запроса которые завершились 401 - для повтороного запроса после успешного обновления токена
    const originalConfig = err.config;
    // console.log('originalConfig', originalConfig._isRetry)
    // console.log(err.config);
    if ((err.response.status === 403) && !originalConfig._isRetry) {
        // предохранитель который запрещает зацыкленный вызов рефреш токена
        // указываем что запрос мы уже делали
        originalConfig._isRetry = true;
        console.log('Пользователь не авторизован e', err);
        if (window.store) {
            window.store.dispatch(authThunkLogoutSystem())
        }
        return Promise.reject(err);
    }


    // if (err.response.status === AuthStatusCode.Forbidden && err.response.data) {
    //     return Promise.reject(err.response)
    // }
    console.log('errror', err)
    return Promise.reject(
        {...err.response.data, status: err.response.status},
    );


});

export const feedbackAPI = {
    sendFeedbackForm(data) {
        return instances.post('mail', {action: "send_form", text: data.text}).then(res => res.data)
    },
    sendPay(text) {
        return instances.post('mail',
            {
                action: 'send_form_for_payment',
                text
            }).then(res => res.data)
            .catch(error => rejectTransform(error))
    }
}

export const mainAPI = {
    getAllFirms() {
        return instances.get('ping/Heccrbqrjhf,kmblbyf[eq/').then(res => res.data)
            .catch(error => rejectTransform(error))
    }
}


export const FirmsApi = {
    getFirms(from = null) {
        const fromData = new FormData();
        fromData.append("from", from)
        return instances.post('authi/get-firms', fromData).then(res => res.data)
            .catch(error => {
                return rejectTransform(error)
            })
    },
    connectedFirms(fromData) {
        return instances.post('authi/append-firm', fromData).catch(error => {
            return rejectTransform(error)
        })
    },
    deleteFirm(api_data) {

        return instances.delete('account/firm', {params: api_data}).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error => {
            return rejectTransform(error)
        })
    }
}

export const NewsApi = {
    getNews(page = 1) {
        return instances.get(`news?page=${page}`).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error => {
            return rejectTransform(error)
        })
    }
}

export const sendMailApi = {
    send: (text)=>{
        return instances.post('/mail', {action: "send_form", text}).then(res=> {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error=> rejectTransform(error))
    }
}
