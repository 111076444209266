import "./font/golostext/Golos_Text_Black.ttf"
import "./font/golostext/Golos_Text_Bold.ttf"
import "./font/golostext/Golos_Text_DemiBold.ttf"
import "./font/golostext/Golos_Text_Medium.ttf"
import "./font/golostext/Golos_Text_Regular.ttf"
import './App.css';


import i18n from "i18next";
import cookie from 'js-cookie';

import React, {useEffect} from "react"
import {DEFAULT_LANG, initLanguage} from "./languages/initLanguage";
import {useDispatch, useSelector} from "react-redux";
import {getNewsThunk, setGlobalLocation} from "./redux/app-reducer";
import HeaderContainer from "./component/Header/HeaderContainer";
import FooterContainer from "./component/Footer/FooterContainer";
import {currentLang} from "./utils/multilingualism";
import SectionTabsContainer from "./component/SectionTabs/SectionTabsContainer";
import useHashTabs from "./Hook/useHashTabs";
import {TAB_HASH_IDS} from "./routing_hash";
import Pages from "./component/Pages";
import {authThunkInitialVerificationUser} from "./redux/auth/auth-thunk";
import {LS_FIRM, LS_LANG} from "./consts/localStorageNaming";
import {verificationSelected} from "./redux/auth/auht-selector";
import Loader from "./component/Loader";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {isCommonLoader} from "./redux/selector/app-selector";
import HelpArticleModal from "./component/Modal/HelpArticle/HelpArticleModal";
initLanguage();


const AppContainer = () => {
    const locale = useSelector(state => state.appPage.locale)
    const [currentTab, switchTab, switchTabAndScrolledSection] = useHashTabs(TAB_HASH_IDS.home, Object.values(TAB_HASH_IDS).map(val=> val))
    return (
        <div className={locale === 'he' ? 'rtl' : ''} id={'body'}>
            <HeaderContainer  switchTabAndScrolledSection={switchTabAndScrolledSection}/>
            <SectionTabsContainer currentTab={currentTab} switchTab={switchTab}/>
            <Pages currentTab={currentTab}/>
            <FooterContainer  switchTabAndScrolledSection={switchTabAndScrolledSection} switchTab={switchTab} currentTab={currentTab}/>
            <HelpArticleModal/>
        </div>
    );

}
const App = () => {
    const location = window.location;
    let local = currentLang(location.pathname);
    const verification = useSelector(verificationSelected);
    const isCommonL = useSelector(isCommonLoader);

    const dispatch = useDispatch();

    useEffect(()=>{
        let firm_id = localStorage.getItem(LS_FIRM);
        dispatch(authThunkInitialVerificationUser(firm_id))
    }, [])

    useEffect(() => {
        let currentLanguageCode = DEFAULT_LANG;
        if (local) {
            currentLanguageCode = local;
        } else if (cookie.get('i18next') || i18n.language) {
            currentLanguageCode = cookie.get('i18next') || i18n.language;
        }
        localStorage.setItem(LS_LANG, currentLanguageCode)
        dispatch(setGlobalLocation(currentLanguageCode));
    }, []);
    useEffect(()=>{
        dispatch(getNewsThunk());
    }, [dispatch])
    return (
        <>
            {!verification.isLoading  &&<AppContainer/>}
            <ToastContainer />
            {verification.isLoading || isCommonL && <Loader/> }
        </>

    )
}

export default App;
